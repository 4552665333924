import { defineStore } from "pinia";
import router from "../router";

export const useMainStore = defineStore("main", {
  state: () => ({
    blobContainerClient:"", //讓其他頁可以刪除使用
    currentPage: "",
    blobsas: "",
    currentContent: "",
    currentProfile: "",
    currentEditItem:"",
    mountedMsg: "",
    currentSortBy: "timeNew",
    allHits: [],
    currentGroupProfile:"",
    currentPackContent:"",
    currentEditMaterialGroupMode:'move', //存放當前是move 或 copy
    isLogin: false,
    version:"v0.230921"
  }),
  getters:{
    getAllHits(){
        return this.allHits
    }
  },
  actions: {
    setIsLogIn(status){
      this.isLogin=status
    },
    setCurrentEditMaterialGroupMode(mode){
      this.currentEditMaterialGroupMode=mode
    },
    setBlobContainerClient(blobClient){
      this.blobContainerClient=blobClient;
    },
    setCurrentEditItem(item){
      this.currentEditItem=item
    },
    setCurrentPage(name) {
      this.currentPage = name;
    },
    setMountedMsg(data) {
      this.mountedMsg = data;
    },
    setBlobSas(str) {
      this.blobsas = str;
    },
    setCurrentContent(item) {
      this.currentContent = item;
    },
    setSortBy(way) {
      this.currentSortBy = way;
    },
    //全域常用
    viewContent(item) {
      this.saveCardHit(item)
      this.setCurrentContent(item);
      router.push({
        name: "Content",
        params: {
          id: item.id,
          type: item.type,
        },
      });
    },
    viewPackContent(packContent) {
      this.currentPackContent = packContent;
      router.push({
        name: "PackContent",
        params: {
          id: packContent.id,
        },
      });
    },

    viewProfile(uploader) {
      this.currentProfile = uploader;
      router.push({
        name: "Profile",
        params: {
          id: uploader.id,
        },
      });
    },
    viewGroupProfile(group){
      this.currentGroupProfile = group;
      router.push({
        name: "GroupProfile",
        params: {
          id: group.id,
        },
      });
    },
    saveCardHit(saveItem) {
      const index = this.allHits.findIndex((item) => item.id == saveItem.id);
      //從0開始計
      index == -1 ? this.allHits.push({ id: saveItem.id, hits: 1 }) : this.allHits[index].hits++;
    },
  },
});
