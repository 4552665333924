import axios from "axios";

// http request 拦截器
axios.interceptors.request.use(
  (config) => {    
    // console.log("OK", config.url.indexOf("api2") != -1);
    // if (config.url.indexOf("api2") == -1 || config.url.indexOf("service/SchoolData") !== -1) {
    //   //api2
    //   return handleHeader(config);
    // }
    if(config.url.indexOf("api2") != -1 && config.url.indexOf("oauth2/login") != -1){
      return config;
    }else{
      return handleHeader(config);
    }
    
  },
  (error) => {
    return Promise.reject(error);
  }
);

function handleHeader(config) {
  if(sessionStorage.getItem("access_token")) {
    config.headers["Authorization"] = "Bearer " + sessionStorage.getItem("access_token");
  }  
  config.headers["Content-Type"] = "application/json";
  config.headers["lang"] = localStorage.getItem("local") || navigator.language.toLowerCase();

  //检查auth_token是否存在
  let auth_token = localStorage.getItem("auth_token");
  //以下為驗證auth_token，無者Logout  先封印
  //if (!auth_token) {
  //    console.log('auth_token失败', config)
  //    loginOut()
  //    sessionStorage.setItem('loginOut', 'localStorage没有auth_token：auth_token失败，重新登录')
  //    return
  //}
  //通过验证设置对应参数
  config.headers["X-Auth-AuthToken"] = auth_token;
  return config;
}

// 退出登錄
//function loginOut() {
//    localStorage.clear()
//    window.location.href = window.location.origin + '/login'
//}

//轉換URL
//本機測試環境對策：1.SSL對策 2.前後端port不一致對策
//內容：
function transferUrlIfTest(url) {
  var domain = "";
  var protocol = window.location.protocol;
  var hostname = protocol + "//" + window.location.hostname + (window.location.port ? ":" + window.location.port : "");
  //domain = hostname.includes("192.168.0.214:5005") ? hostname.replace("192.168.0.214:5005", "192.168.0.214:5004") : "";
  //domain = hostname.includes("192.168.0.232:5005") ? hostname.replace("192.168.0.232:5005", "192.168.0.232:5004") : "";
  domain = hostname.includes("localhost:5005") ? hostname.replace("localhost:5005", "localhost:5004") : "";
  return `${domain}${url}`;
}

/**
 * 封装get方法
 * @param url
 * @param data
 * @returns {Promise}
 */

export function get(url, params) {
  url = transferUrlIfTest(url);
  let data = {};
  data.method = url;
  data.params = params;
  data.lang = localStorage.getItem("local");
  return new Promise((resolve, reject) => {
    axios
      .get(url, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */
export function post(url, params) {  
  if(url.indexOf("api2") == -1 && url.indexOf("https://bb-rc") == -1 
  && url.indexOf("https://www.teammodel.net") == -1 // ies5正式站
  && url.indexOf("https://ies5-rc.teammodel.net") == -1 // ies5 RC站
  && url.indexOf("https://test.teammodel.net") == -1 // ies5 test站
    ){
    url = transferUrlIfTest(url);
  }
  // console.log(url)  
  let data = {url};
  data.method = url;
  data.params = params;
  data.lang = localStorage.getItem("local");
  return new Promise((resolve, reject) => {
    axios.post(url, params).then(
      (response) => {
        if (response) {
          resolve(response.data);
        }
      },
      (err) => {
        reject(err);
      }
    );
  });
}