import { createApp } from "vue";
import { createPinia } from "pinia";

import App from "./App.vue";
import router from "./router";
import i18n from "./locale";
import VueLazyload from 'vue-lazyload'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import {createHead} from '@vueuse/head'


import "./assets/icons/index.js";
const pinia = createPinia();
const head = createHead();

const app = createApp(App);

//全局註冊所有ui組件
app.use(ElementPlus);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}

// 路由規則：目前只有Setting頁需要登入才能看
router.beforeEach((to, from, next) => {
  // 如果是桃園網域的首頁  要導致桃園專屬頁面  
  if (document.location.hostname === 'taoyuan.teammodel.net' &&    
      document.location.pathname === '/' && to.name !== 'Home') {  
    next({ name: 'HomePage_Taoyuan' });
  } else if (to.meta && to.meta.requiresAuth) {
    sessionStorage.getItem("id_token") ? next() : next({ name: "HomePage_Taoyuan" });
  } 
  // else if( 
  //   //Cname === 'event.teammodel.net' && 
  //   to.query.sid !== undefined){
  //   next({ name: 'Activity_Info' });
  // } 
  else {
    next();
  }
});

app.use(head)
app.use(pinia);
app.use(router);
app.use(i18n);
app.use(ElementPlus)
app.provide("$t", i18n.global.t); //讓$t再vue3 setup可用
app.use(VueLazyload, {
  preLoad: 1.3,
  loading: '/loading2.gif',
})

//卡片背景圖懶加載
app.directive("lazy-background", {
  mounted(el, binding) {
    const options = {
      rootMargin: "0px",
      threshold: 0.3, // 調整這個數值以控制加載時間
    };
    // 將 rootMargin 設置為 '0px'，這意味著元素的邊界即將進入視窗時就會觸發 Intersection Observer，並開始加載圖片。
    // 調整 threshold 的值（範圍從 0 到 1），來控制何時開始加載圖片。如果將 threshold 設置為 0.3，則當元素的 30% 進入視窗時就會開始加載圖片。

    const imageObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          el.style.backgroundImage = binding.value;
          imageObserver.unobserve(entry.target);
        }
      });
    }, options);

    imageObserver.observe(el);
  },
});

app.mount("#app");
