// useGlobalMethods.js
import { useRouter } from 'vue-router';

export function useGlobalMethods() {
    const router = useRouter();
    // 全站共用方法
    //8.16 註解sessionStorage.removeItem("info")  原因是活動頁登出會因為沒有info而出錯
    const afterLoginRouter = () => {
        if (sessionStorage.getItem("info")) {            
            if (sessionStorage.getItem("info") === "1") {
              if(location.pathname === "/AccountInfo"){
                //如果目前在授權頁 導去首頁
                router.push("/")
              }else{
                // 從統購頁進入 導去授權頁
                router.push("/AccountInfo")
              }                                         
            } else if (sessionStorage.getItem("info") === "2") {
              if(location.pathname === "/"){// 如果目前在首頁就回到首頁
                router.push("/")
              }else{
                // 否則 從活動頁進入 導去活動頁                   
                location.href = location.origin + "/Activity_Info";                         
              }                
            } else if (sessionStorage.getItem("info") === "3") {
              if(location.pathname === "/"){// 如果目前在首頁就回到首頁
                router.push("/")
              }else{
                // 否則 從特定活動頁進入 導去特定活動頁
                location.href = location.origin + "/goodkid";                              
              }                       
            } else {
              router.push("/")
            }
          } else {
            router.push("/")
          }     
    }

  

  return {    
    afterLoginRouter
  };
}