<template>
  <div class="home-page div-center div-centerY" v-loading="loadingCtrl">
    <div class="explain">{{$t('AccountLogin.chooseMethodToProceed')}}</div>
    <div class="timeInfo">{{$t('AccountLogin.completeWithin30Minutes', { time: timerStore.formattedTime })}}</div>
    <div class="oneLine buttonGroup">
      <div>
        <div class="button"  @click="goTo('/Binding_Choose')">
          <img class="buttomImg" src="../../assets/img/area-08.png" alt="">
            <div class="pngText">
              {{haveTeamModelAccount[0]}}<br>{{haveTeamModelAccount[1]}}
            </div>
        </div>
        <div class="info">{{$t('AccountLogin.bindExistingTeamModelAccount')}}</div>
      </div> 
      <div>
        <div class="button" @click="doubleCheckfun()">
          <img class="buttomImg" src="../../assets/img/area-01.png" alt="">
            <div class="pngText">
              {{noTeamModelAccount[0]}}<br>{{noTeamModelAccount[1]}}
            </div>
        </div>
        <div class="info">{{$t('AccountLogin.registerAndBindNewAccount')}}</div>
      </div>
    </div>
    <img class="beanPic" src="../../assets/img/bean/bean_07.png" alt="">
    <div class="backDiv" @click="goback()">{{$t('AccountLogin.back')}}</div>
  </div>
</template>

<script setup>
import { inject,computed,ref, onMounted, onUnmounted  } from 'vue'//,ref,reactive
import { ElMessage, ElMessageBox } from 'element-plus';
//import { onMounted, ref } from 'vue'
import { useRouter, useRoute  } from 'vue-router'
import { post } from "@/api/http";
import profile from "../../api/profile";
import option_gl from '@/static/regions/region_gl.json'
const optionsData = option_gl[0].children
import { useTimerStore } from '../../stores/timer';
const timerStore = useTimerStore();
import { useGlobalMethods } from '@/utils/useGlobalMethods';

const { afterLoginRouter } = useGlobalMethods();
//路由
const route  = useRoute()
const routers = useRouter();
const serverLocation = "global";
const clientID = profile.clientID[serverLocation];
const coreAPIUrl = profile.apiUrl[serverLocation];
const gt_educloudtw = "educloudtw";


let loadingCtrl =ref(false);//載入畫面控制

const t = inject('$t');//三國語系

//字串組
const haveTeamModelAccount = computed(() =>t('AccountLogin.haveTeamModelAccount').split(','));
const noTeamModelAccount = computed(() =>t('AccountLogin.noTeamModelAccount').split(','));

checkAccount();

onMounted(() => {  
  timerStore.stopTimer();
  timerStore.loadTimer();
  setTimeout(() => {
  timerStore.startTimer();
      }, "500");
});

onUnmounted(() => {
  if (timerStore.intervalId) {
    clearInterval(timerStore.intervalId);
  }
});


// 檢查帳號狀態
function checkAccount() {
  loadingCtrl.value = true
  //https://account.teammodel.net/quickopencode/educloudtw?client_id=4a6fd5c8-dfe2-4aef-ad9c-d5cb04488ae3&callback=https://communtiy.teammodel.net
  //console.log('checkAccount');

  //檢查有沒有opencode 
  //https://communtiy.teammodel.net/?open_code=ahOkmzyo00llhfQB7c3TlNhN01CwZCPd__JX9r-X6eg
  //debugger
  let opencode = route.query.open_code;
  //let url = coreAPIUrl;  
  if (opencode !== "" && opencode !== null && opencode !== undefined) {
    if (sessionStorage.getItem("open_id")) {
      loadingCtrl.value = false;
    } else {
      //如果有opencode   進入下一步檢查 呼叫coreservice 檢查有沒有綁定醍摩豆ID    
      let data = {
        grant_type: gt_educloudtw,
        client_id: clientID,
        nonce: "0",
        open_code: opencode,
        redirect_uri: "https://account.teammodel.net/",
        lang: "zh-tw"
      }
      post(coreAPIUrl + '/oauth2/login', data).then(async res => {
        if (res.id_token && res.access_token) {
          //有綁定醍摩豆ID        
          sessionStorage.setItem("access_token", res.access_token)
          sessionStorage.setItem("id_token", res.id_token)
          sessionStorage.setItem("open_name", res.open_name)
          sessionStorage.setItem("open_mail", res.open_mail)

          //拿到校id 
          //用校id取校名
          await getSchoolData(res.school_id);

          //(登入狀態)進入下一頁
          afterLoginRouter();        
          

        } else {

          //沒有 把open_id存起來 進入下一步檢查 留在此頁檢查 有沒有醍摩豆ID 
          sessionStorage.setItem("open_id", res.open_id)
          loadingCtrl.value = false;
        }
      }, err => {
        console.log(err)
      })
    }
  } else {//沒有  返回首頁
    routers.push("/")
  }
}

//『我沒有醍摩豆帳號』按鈕事件
// const t = inject('$t');
function doubleCheckfun() {
  ElMessageBox.confirm(
    t('AccountLogin.registerNewAccountPrompt'),
    t('AccountInfo.inquiry'),
    {
      confirmButtonText: t('AccountLogin.confirm'),
      cancelButtonText: t('AccountLogin.cancel'),
      type: 'warning',
    }
  ).then(() => {
    

    //2. 再用取得的open_id打第二次創新帳號並綁定
    let data =
    {
      grant_type: gt_educloudtw,
      client_id: clientID,
      nonce: "0",
      open_code: sessionStorage.getItem("open_id"),      
      lang: "zh-tw"
    }
    post(coreAPIUrl + '/oauth2/login', data).then(async res => {
      if (res.id_token && res.access_token) {
        {
          sessionStorage.setItem("access_token", res.access_token)
          sessionStorage.setItem("id_token", res.id_token)
          sessionStorage.setItem("open_name", res.open_name)
          await getSchoolData(res.school_id);          
          await ElMessage({
            type: 'success',
            message:  t('AccountLogin.bindingSuccessful'),
          })
          sessionStorage.removeItem("open_id");// 綁定完成清除open_id的Storage
          afterLoginRouter()
              
        }
      }
    }), err => {
      console.log(err)
    }
  }).catch(() => {//取消
      ElMessage({
        type: 'info',
        message: t('AccountLogin.cancel'),
      })
    });
}
// 取得學校資料
async function getSchoolData(school_id){
  let schdata = {
          code: school_id,
          fullData: true
        }
        await post(coreAPIUrl + '/service/SchoolData', schdata).then(schres => {
          sessionStorage.setItem("school_name", schres[0].name)//校名
          sessionStorage.setItem("shortCode", schres[0].shortCode)
          sessionStorage.setItem("schoolCode", schres[0].code)          

          //用cityId取縣市名
          let odItem = optionsData.find(od => od.code == schres[0].cityId)
          sessionStorage.setItem("region_name", odItem.name)//縣市名
          sessionStorage.setItem("cityId", schres[0].cityId)        
        }) 

}
function goback(){//返回
  sessionStorage.removeItem('open_id');
  // location.href = "https://oidc.tanet.edu.tw/home";
  // ElMessageBox.alert(
  //   '請使用者先在 <a href="https://oidc.tanet.edu.tw/auth-logout" target="_blank" style="color: blue; text-decoration: underline;">此頁</a> 進行登出，再點OK。', 
  //   '通知', 
  //   {
  //     confirmButtonText: 'OK',
  //     dangerouslyUseHTMLString: true,
  //      callback: (action) => {
  //       if (action === 'confirm') {
  //         afterLoginRouter();
  //       }
  //     }
  //   }
  // );
  afterLoginRouter();
}

function goTo(val){
  routers.push(val);
}

</script>

<style lang="less" scoped>
@fontColor :#424f55;//文字：深藍
@fontColor-light :#8da3a0;//文字：中藍
@stressColor :#f59f56;//強調色：橘色
@fontColor-gray1: #909399;
@fontColor-gray2: #b1b3b8;

.div-center{/*水平置中*/
  display: flex; 
  justify-content: center; 
  text-align:center;
  width:100%;
}
.oneLine{ /*單行與折行顯示*/
  display: flex;
  flex-wrap: wrap; //元素折行呈現，元素空間夠的時候單行呈現，空間不夠則折行呈現
}
.div-centerY{ /*垂直置中*/
  display: flex;    
  align-items: center;
}
.home-page{
  margin-top: 50px;
  .explain{
    font-size: 20px;
    font-weight:bold;    
  }
  .timeInfo{
    color: @fontColor-light;
    margin-top: 10px;
    font-style:oblique;
  }
  .buttonGroup{
    margin: 30px 0px;
    .button{
      margin: 0px 50px;
      position: relative;
      cursor: pointer;//滑鼠變成小手
      .buttomImg{
        width: 300px;
        display: block;      
      }
      .pngText{
        position: absolute;
        transform: translate(0%, -140%);
        color: white;
        font-size: 30px;
        width: 100%;
        font-weight:bold;
      }
    }
    .info{
      color:@fontColor-gray1;
      margin: 20px 0 40px 0;;
    }
  }
  .beanPic{
    height: 150px;
  }
  .backDiv{
    margin-top: 30px;
    color: @fontColor ;
    cursor: pointer;//滑鼠變成小手
    margin-bottom: 70px;
  }
}
</style>
